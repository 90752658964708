import React from 'react'

import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				author
				authorAvatar
			}
		}
		allMarkdownRemark( sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				id
				fields {
					slug
					readingTime {
						text
					}
				}
				excerpt(format: PLAIN, pruneLength: 100, truncate: true)
				frontmatter {
					date(formatString: "DD MMMM, YYYY", locale: "ru-RU")
					title
					seo_description
					seo_title
					featured {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
						}
					}
					thumb {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			}
		}
	}
`

const Blog = ({ data }) => {
	const posts = data.allMarkdownRemark.nodes
	const author = data.site.siteMetadata.author
	const authorAvatar = data.site.siteMetadata.authorAvatar

	// if(posts.length === 0) {
		return (
			<Layout>
				<Seo title='Блог' />

				<div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
					<div className="relative max-w-7xl mx-auto">
						<div className="text-center">
							<h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Блог</h2>
							<p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
								Пишем полезные материалы по созданию и развитию сайта.
							</p>
						</div>
						<div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
							{posts.map((post) => {

								const title = post.frontmatter.title
								const seoTitle = post.frontmatter.seo_title
								const seoDescription = post.frontmatter.seo_description
								const excerpt = post.excerpt
								const date = post.frontmatter.date
								const postUrl = `/blog${post.fields.slug}`
								const featuredImg = getImage(post.frontmatter.featured)
								const thumbImg = getImage(post.frontmatter.thumb)
								const readingTime = post.fields.readingTime.text

							return (
								<div key={post.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
									<div className="flex-shrink-0">
										<Link to={postUrl} >
										<GatsbyImage className="h-48 w-full object-cover" image={featuredImg} alt={title} />
										</Link>
										{/* <img className="h-48 w-full object-cover" src={post.frontmatter.imgurl} alt="" /> */}
									</div>
									<div className="flex-1 bg-white p-6 flex flex-col justify-between">
										<div className="flex-1">
											<p className="hidden text-sm font-medium text-indigo-600">
												<Link to="#" className="hover:underline">
													{date}
												</Link>
											</p>
											<Link to={postUrl} className="block mt-2">
												<p className="text-xl font-semibold text-gray-900">{title}</p>
												<p className="mt-3 text-base text-gray-500">{excerpt}</p>
											</Link>
										</div>
										<div className="mt-6 flex items-center">
											<div className="flex-shrink-0">
												<Link to="/about">
													<span className="sr-only">{date}</span>
													{/* <GatsbyImage className="h-10 w-10 rounded-full" image={authorAvatar} alt={author} /> */}
													<img className="h-10 w-10 rounded-full" src={authorAvatar} alt={author} />
												</Link>
											</div>
											<div className="ml-3">
												<p className="text-sm font-medium text-gray-900">
													<Link to="/about" className="hover:underline">
														{author}
													</Link>
												</p>
												<div className="flex space-x-1 text-sm text-gray-500">
													<time dateTime={date}>{date}</time>
													<span aria-hidden="true">&middot;</span>
													<span>{readingTime}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)})}
						</div>
					</div>
				</div>
			</Layout>
		)
	// }

}

export default Blog